<template>
  <!--公共-底部条-->
  <div class="bottom-bar">
    <div id="share-2" class="share-2" />
    <div>
      <div class="item">
        <div>技术支持： 云南开屏信息技术有限责任公司</div>
        <div class="line" />
        <div>电话： 0871-65010159</div>
        <div class="line" />
        <div>举报投诉电话： 0871-65010159</div>
      </div>
      <div class="item">
        <div>版权所有： 云南开屏信息技术有限责任公司</div>
        <div class="line" />
        <div>地址：云南省昆明市云报传媒广场A座16楼</div>
        <div class="line" />
        <div @click="goToNewPage">
          <a href="">滇ICP备19010106号-2</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      window.socialShare('#share-2', { sites: ['qzone', 'qq', 'weibo', 'wechat'] })
    })
  },
  methods: {
    goToNewPage () {
      window.open('https://beian.miit.gov.cn')
    }
  }
}
</script>

<style scoped lang="scss">
.bottom-bar {
  //position: fixed;
  //z-index: 99;
  //left: 0;
  //bottom: 0;
  width: 100vw;
  background-color: #ffffff;
  font-size: 18px;
  text-align: center;
  padding: 15px;
  color: #999999;

  > div {
    margin-left: 50%;
    transform: translateX(-50%);
    display: table;
  }

  .item {
    display: flex;
    vertical-align: center;
    align-items: center;
    white-space: nowrap;

    > div {
      //&:first-child {
      margin-right: 30px;
      //}
    }

    .line {
      width: 2px;
      min-width: 2px;
      background-color: #7E7E7E;
      height: 15px;
      margin-right: 30px;
    }
  }
}
</style>
